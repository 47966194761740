<template>
  <div class="home">
    <header :class="[hederStyle, 'header']">
      <div class="header-container">
        <h1 class="logo">
          <img :src="logoSrc" alt="财付通小贷logo" />
        </h1>

        <!-- pc菜单 -->
        <nav id="navigator" v-if="!isUseMobileStyle">
          <ul>
            <li
              class="nav-li"
              v-for="(item, index) in navList"
              :key="item.title"
              @mouseover="hoverIndex = index"
              @mouseleave="hoverIndex = ''"
            >
              <div
                class="fp-tooltip animate-item-faster2"
                :class="`${
                  index === activePage - 1 || hoverIndex === index
                    ? 'fp-tooltip-active'
                    : 'fp-tooltip'
                }`"
              >
                <a @click="clickMenu1(index + 1, item)" :href="item.enTitle"> {{ item.title }} </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <nav v-if="isUseMobileStyle" class="menu">
        <div class="menu" @click="clickMenu">
          <img class="menu-icon" :src="moreMenu ? menuSrc : closeSrc" alt="菜单图片" />
        </div>
      </nav>
      <!-- 移动端弹出菜单 -->
      <div class="menuModal" v-if="!moreMenu && isUseMobileStyle">
        <ul>
          <li
            class="nav-li"
            v-for="(item, index) in navList"
            :key="item.title"
            @mouseover="hoverIndex = index"
            @mouseleave="hoverIndex = ''"
          >
            <div
              class="fp-tooltip"
              :class="`${
                index === activePage - 1 || hoverIndex === index
                  ? 'fp-tooltip-active'
                  : 'fp-tooltip'
              }`"
            >
              <a :href="item.enTitle" @click="moreMenu = true"> {{ item.title }} </a>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="main">
      <section class="section" id="home">
        <div class="main-video-container">
          <video
            class="main-video"
            muted
            loop
            :autoplay="autoplay"
            preload="none"
            poster="@/assets/bg/bg1.png"
            :x5-playsinline="true"
            :webkit-playsinline="true"
            x5-video-player-type="h5"
            :x-webkit-airplay="true"
            :playsinline="true"
          >
            <source src="@/assets/videos/bg3.mp4" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <!-- <div class="main-video-container" v-if="!autoplay">
          <div class="main-video" id="container">
            <span class="loading-box">视频加载中...</span>
            <img :src="videosImg" alt="首页图片" />
          </div>
        </div> -->
      </section>
      <!-- 需求修改删除了产品介绍,原index为2 -->
      <section class="section" id="info">
        <div class="main-video-container info-announce">
          <div class="title">
            <div class="title-top">
              <span>
                <img src="@/assets/bg/left-icon.png" alt="信息公告图片1" />
              </span>
              <h2>金融消费者教育</h2>
              <span>
                <img src="@/assets/bg/right-icon.png" alt="信息公告图片2" />
              </span>
            </div>
            <div class="info-title-bottom"></div>
          </div>
          <div class="info-list">
            <div class="info-list-left">
              <div class="msg-list-wrap">
                <div
                  v-for="(item, index) in educationDataList.data"
                  :key="index"
                  class="msg-item"
                  @click="handle(item)"
                >
                  <div class="table-title">{{ item.title }}</div>
                  <div class="table-time">{{ item.time }}</div>
                </div>
              </div>
              <el-pagination
                style="text-align: right"
                class="table-pagination"
                layout="total,prev, pager, next"
                :total="educationDataList.list.length"
                :page-size="7"
                @current-change="handleEducationChange"
              >
              </el-pagination>
            </div>
            <div class="info-list-right">
              <img :src="isUseMobileStyle ? bg9 : bg6" alt="财付通小贷背景图1" srcset="" />
            </div>
          </div>
        </div>
      </section>
      <section class="section" id="education">
        <div class="main-video-container info-announce">
          <div class="title">
            <div class="title-top">
              <span>
                <img src="@/assets/bg/left-icon.png" alt="信息公告图片1" />
              </span>
              <h2>信息公告</h2>
              <span>
                <img src="@/assets/bg/right-icon.png" alt="信息公告图片2" />
              </span>
            </div>
            <div class="info-title-bottom"></div>
          </div>
          <div class="education-list">
            <div class="education-list-right">
              <img :src="isUseMobileStyle ? bg11 : bg10" alt="财付通小贷背景图2" srcset="" />
            </div>
            <div class="education-list-left">
              <div class="msg-list-wrap">
                <div
                  v-for="(item, index) in infoDataList.data"
                  :key="index"
                  class="msg-item"
                  @click="handle(item)"
                >
                  <div class="table-title">{{ item.title }}</div>
                  <div class="table-time">{{ item.time }}</div>
                </div>
              </div>
              <el-pagination
                style="text-align: right"
                class="table-pagination"
                layout="total,prev, pager, next"
                :total="infoDataList.list.length"
                :page-size="7"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </section>
      <section class="section" id="aboutus">
        <div class="main-video-container info-announce about">
          <div class="title">
            <div class="title-top">
              <span>
                <img src="@/assets/bg/left-icon.png" alt="关于我们图1" />
              </span>
              <h2>关于我们</h2>
              <span>
                <img src="@/assets/bg/right-icon.png" alt="关于我们图2" />
              </span>
            </div>

            <div class="info-title-bottom"></div>
          </div>
          <div class="about-list">
            <div class="about-list-left">
              <img :src="isUseMobileStyle ? bg8 : bg5" alt="关于我们图3" srcset="" />
            </div>
            <div class="about-list-right">
              <div class="about-list-right-con">
                <div class="about-time">
                  深圳市财付通网络金融小额贷款有限公司于2013年10月成立，注册地在深圳市前海深港合作区。
                </div>
                <div class="about-conect">联系我们：客服专线(大陆地区)95017</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="footer">
      <div class="record-number" @click="toRecord">粤ICP备19022746号</div>
    </footer>
    <div class="toTop" @click="toTop" v-if="hederStyle && isUseMobileStyle">
      <img src="@/assets/bg/to-top.png" alt="返回顶部图片" />
    </div>
  </div>
</template>

<script>
import customAni from '@/utils/animation';
import logonew from '@/assets/icons/new-logo.png';
import logo from '@/assets/icons/logo.png';
import menuWhite from '@/assets/bg/menu-white.png';
import menuBlack from '@/assets/bg/menu-black.png';
import closeBlack from '@/assets/bg/close.png';
import closeWhite from '@/assets/bg/close-white.png';
import bg5 from '@/assets/bg/bg5.png';
import bg6 from '@/assets/bg/bg6.png';
import bg8 from '@/assets/bg/bg8.png';
import bg9 from '@/assets/bg/bg9.png';
import bg10 from '@/assets/bg/bg10.png';
import bg11 from '@/assets/bg/bg11.png';
import videoBg from '@/assets/bg/bg1.png';
import { isMobile, isAndroid } from '@/utils/common';
import './styles/mobile.scss';
import './styles/pc.scss';
import { mtaReport, MTA_MENU_ID } from '@/utils/mta/mta';

const policyTitle = '财付通小贷隐私政策公告';
const policyPath = '/privacyPolicy';

export default {
  name: 'home',
  components: {},
  data() {
    return {
      activePage: 1,
      hoverIndex: '',
      view: {},
      navList: [
        {
          title: '首页',
          enTitle: '#home',
          event: 'xiaodai_net_home.click',
          menuId: MTA_MENU_ID.home,
        },
        {
          title: '金融消费者教育',
          enTitle: '#info',
          event: 'xiaodai_net_education.click',
          menuId: MTA_MENU_ID.education,
        },
        {
          title: '信息公告',
          enTitle: '#education',
          event: 'xiaodai_net_info.click',
          menuId: MTA_MENU_ID.info,
        },
        {
          title: '关于我们',
          enTitle: '#aboutus',
          event: 'xiaodai_net_aboutus.click',
          menuId: MTA_MENU_ID.about,
        },
      ],

      infoDataList: {
        list: [
          {
            title: '公司合规声明',
            path: policyPath,
            time: '2023-08-25',
            nbr: 6,
            event: 'xiaodai_net_info.click.notice5',
            menuId: MTA_MENU_ID.info,
          },
          {
            title: policyTitle,
            path: policyPath,
            time: '2023-01-31',
            nbr: 5,
            event: 'xiaodai_net_info.click.notice4',
            menuId: MTA_MENU_ID.info,
          },
          {
            title: policyTitle,
            path: policyPath,
            time: '2022-07-26',
            nbr: 3,
            event: 'xiaodai_net_info.click.notice3',
            menuId: MTA_MENU_ID.info,
          },
          {
            title: policyTitle,
            path: policyPath,
            time: '2021-11-01',
            nbr: 2,
            event: 'xiaodai_net_info.click.notice2',
            menuId: MTA_MENU_ID.info,
          },
          {
            title: policyTitle,
            path: policyPath,
            time: '2021-06-17',
            nbr: 1,
            event: 'xiaodai_net_info.click.notice1',
            menuId: MTA_MENU_ID.info,
          },
        ],
        data: [],
      },
      educationDataList: {
        list: [
          {
            title: '爱征信 惠民生 助发展 | 个人征信报告解读之正面信息和负面信息',
            path: '/education',
            nbr: 18,
            time: '2024-09-19',
            event: 'xiaodai_net_education.click.notice21',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '爱征信 惠民生 助发展 | 614 信用记录关爱日主题宣传',
            path: '/education',
            nbr: 17,
            time: '2024-06-14',
            event: 'xiaodai_net_education.click.notice20',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '警惕冒充客服的虚假征信类诈骗',
            path: '/education',
            nbr: 16,
            time: '2024-05-14',
            event: 'xiaodai_net_education.click.notice19',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '315专题《金融消费者八项权益——猜猜你了解多少》',
            path: '/education',
            nbr: 15,
            time: '2024-03-14',
            event: 'xiaodai_net_education.click.notice18',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '消费者风险提示：珍惜个人征信 护航美好生活',
            path: '/education',
            nbr: 14,
            time: '2024-03-14',
            event: 'xiaodai_net_education.click.notice17',
            menuId: MTA_MENU_ID.education,
          },

          {
            title: '“反洗钱”漫画小课堂',
            path: '/education',
            nbr: 13,
            time: '2023-12-19',
            event: 'xiaodai_net_education.click.notice16',
            menuId: MTA_MENU_ID.education,
          },

          {
            title: '通过5起经典案例揭秘网络诈骗骗局',
            path: '/education',
            nbr: 12,
            time: '2023-06-26',
            event: 'xiaodai_net_education.click.notice15',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '十年征信路，奋进新征程',
            path: '/education',
            nbr: 11,
            time: '2023-06-19',
            event: 'xiaodai_net_education.click.notice14',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '“互联网贷款”宣传教育',
            path: '/education',
            nbr: 10,
            time: '2023-03-09',
            event: 'xiaodai_net_education.click.notice13',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '一文读懂《征信业务管理办法》',
            path: '/education',
            nbr: 9,
            time: '2022-12-12',
            event: 'xiaodai_net_education.click.notice12',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '“小红花公益周”|做公益、促党建、学消保',
            path: policyPath,
            time: '2022-09-26',
            nbr: 4,
            event: 'xiaodai_net_education.click.notice11',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '《小心有诈》——贷款反诈宣传',
            path: '/propaganda',
            nbr: 2,
            time: '2022-09-26',
            event: 'xiaodai_net_education.click.notice10',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '一起来识毒 防毒 拒毒',
            path: '/propaganda',
            nbr: 1,
            time: '2022-06-29',
            event: 'xiaodai_net_education.click.notice9',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '爱惜个人“信用名片”，警惕“征信修复”骗局',
            path: '/education',
            nbr: 8,
            time: '2022-05-31',
            event: 'xiaodai_net_education.click.notice8',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '互联网贷款的注意事项和常见风险',
            path: '/education',
            nbr: 7,
            time: '2022-03-15',
            event: 'xiaodai_net_education.click.notice7',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '套路贷、信贷诈骗！一文带你了解背后的黑幕',
            path: '/education',
            nbr: 6,
            time: '2021-09-03',
            event: 'xiaodai_net_education.click.notice6',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '“反洗钱知多少”主题宣传教育',
            path: '/education',
            nbr: 5,
            time: '2021-08-13',
            event: 'xiaodai_net_education.click.notice5',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '防范非法集资宣传教育第二期',
            path: '/education',
            nbr: 4,
            time: '2021-06-28',
            event: 'xiaodai_net_education.click.notice4',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '防范非法集资宣传教育第一期',
            path: '/education',
            nbr: 3,
            time: '2021-06-22',
            event: 'xiaodai_net_education.click.notice3',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '防范非法集资宣传教育',
            path: '/education',
            nbr: 2,
            time: '2021-06-17',
            event: 'xiaodai_net_education.click.notice2',
            menuId: MTA_MENU_ID.education,
          },
          {
            title: '“网络贷款诈骗”金融教育者活动',
            path: '/education',
            nbr: 1,
            time: '2021-06-17',
            event: 'xiaodai_net_education.click.notice1',
            menuId: MTA_MENU_ID.education,
          },
        ],
        data: [],
      },
      customAni,
      currentPage: 1,
      sliceData: {
        size: 0,
        result: [],
      },
      hederStyle: '',
      logoSrc: logonew,
      menuSrc: menuWhite, // 菜单图表路径
      isUseMobileStyle: false, // 是否使用移动端样式
      moreMenu: true, // 是否显示的菜单图标
      closeSrc: closeWhite,
      bg5,
      bg6,
      bg8,
      bg9,
      bg10,
      bg11,
      videosImg: videoBg,
      timer: '',
      timerIndex: 0,
      timeTag: 0,
      interval: 140,
      imgList: {},
    };
  },
  mounted() {
    // 埋点
    mtaReport('xiaodai_net.click', MTA_MENU_ID.home);
    this.handleCurrentChange(1);
    this.handleEducationChange(1);
    setTimeout(() => this.toTop(), 100);
    window.addEventListener('scroll', this.handleScroll);
    // todo 后续如果其他地方也需要添加移动端样式，该监听应在appjs里只初始化一次
    this.isUseMobileStyle = window.innerWidth < 769;
    window.onresize = () => (this.isUseMobileStyle = window.innerWidth < 769);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 关闭
    }
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    autoplay() {
      return !isMobile() || !isAndroid();
    },
  },
  methods: {
    handle(row) {
      const { path, nbr, event, menuId } = row;
      const newhref = this.$router.resolve({ path, query: nbr ? { type: nbr } : '' });
      window.open(newhref.href, '_blank');
      // 埋点
      mtaReport(event, menuId);
    },
    toRecord() {
      window.open('https://beian.miit.gov.cn', '_blank');
    },
    // 信息公告分页
    handleCurrentChange(val) {
      // 每页7条
      this.sliceData = this.getNewArray(this.infoDataList.list, 7);
      // 提取对应数据
      this.infoDataList.data = this.sliceData.result[val - 1];
    },
    // 金融消费者教育分页
    handleEducationChange(val) {
      // 每页7条
      this.sliceData = this.getNewArray(this.educationDataList.list, 7);
      // 提取对应数据
      this.educationDataList.data = this.sliceData.result[val - 1];
    },
    getNewArray(arr, size) {
      // size，要分割的长度,即每页的条数
      const arrNum = Math.ceil(arr.length / size, 10); // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
      let index = 0; // 定义初始索引
      let resIndex = 0; // 用来保存每次拆分的长度
      const result = [];
      while (index < arrNum) {
        result[index] = arr.slice(resIndex, size + resIndex);
        resIndex += size;
        index++;
      }
      return { size, result };
    },
    handleScroll() {
      const info = document.getElementById('info');
      const education = document.getElementById('education');

      const scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (education.offsetTop <= scroll + 80) {
        this.hederStyle = 'hederStyle';
        this.logoSrc = logo;
        this.menuSrc = menuBlack;
        this.closeSrc = closeBlack;
        this.activePage = 3;
      } else if (info.offsetTop <= scroll + 80) {
        this.hederStyle = 'hederStyle';
        this.logoSrc = logo;
        this.menuSrc = menuBlack;
        this.closeSrc = closeBlack;
        this.activePage = 2;
      } else {
        this.hederStyle = '';
        this.logoSrc = logonew;
        this.menuSrc = menuWhite;
        this.closeSrc = closeWhite;
        this.activePage = 1;
      }
      const h = education.offsetTop + education.offsetHeight / 2;
      // 变量windowHeight是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 变量scrollHeight是滚动条的总高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // 滚动条到底部的条件
      if (h < scroll || scroll + windowHeight + 1 >= scrollHeight) {
        this.activePage = 4;
      }
    },
    // 返回顶部
    toTop() {
      $('html,body').animate({ scrollTop: 0 }, 500);
    },
    clickMenu() {
      this.moreMenu = !this.moreMenu;
    },
    clickMenu1(index, row) {
      // 埋点
      const { menuId, event } = row;
      mtaReport(event, menuId);

      setTimeout(() => {
        this.activePage = index;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep tbody tr:hover > td:nth-child(1) {
  color: #2c57ec;
  cursor: pointer;
}

::v-deep .el-table td {
  color: #333333;
  border: none;
  cursor: pointer;
}

::v-deep .el-pager li.active,
::v-deep .el-pager li:hover {
  color: #2c57ec;
  cursor: pointer;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-pagination button:hover {
  color: #2c57ec;
}

::v-deep .el-pagination,
::v-deep .el-pagination .el-pagination__total {
  color: #333;
}

.msg-list-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  .msg-item {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    color: #000000;

    .table-time {
      font-family: 'Arial';
    }
  }
}

.table-title:hover {
  color: #2c57ec;
  cursor: pointer;
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.footer {
  width: 100%;
  // min-width: 1200px;
  // height: 86px;
  background: #f5f5f5;
  // font-size: 18px;
  color: #666666;

  // line-height: 86px;
  .record-number {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hederStyle {
  background: #ffffff;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.06);

  #navigator {
    .nav-li {
      .fp-tooltip a {
        color: #666666;
      }

      .fp-tooltip-active a {
        color: #333333;
        border-bottom: solid 3px #2c57ec;
      }
    }
  }
}

.header {
  width: 100%;
  // min-width: 1200px;
  // height: 86px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 1000;
}

// 动画
.an1-enter-active,
.an1-leave-active {
  transition: all 1.7s;
}

.an1-enter,
.an1-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(50%);
  opacity: 0;
}

.an1-leave,
.an1-enter-to

/* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(0%);
  opacity: 1;
}

// 加大动画幅度animate-item-later2
.an1-enter,
.an1-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  &.animate-item-later2 {
    transform: translateX(70%);
    opacity: 0;
  }
}

// 加大动画幅度animate-item-later3
.an1-enter,
.an1-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  &.animate-item-later3 {
    transform: translateX(90%);
    opacity: 0;
  }
}

// 缩短动画时间animate-item-faster2
.an1-enter-active,
.an1-leave-active {
  &.animate-item-faster2 {
    transition: all 0.8s;
  }
}
</style>
